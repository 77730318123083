import React from "react"
import {
  Box,
  Heading,
  Container,
  Stack,
  HStack,
  Text,
  Flex,
} from "@chakra-ui/react"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'
import { FaDiscord, FaFacebookSquare, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa"

export const BasePageTemplate: React.FC<{
  maxW: string,
  children: React.ReactNode
}> = ({
  maxW,
  children
}) => {

    return (
      <Flex direction="column" w="100%" minH="100vh" bgColor="ckNavy" textColor="white" textAlign="center">
        <Stack direction="column" alignItems="center" justifyItems="center" justifyContent="center" alignContent="center" pt={12}>
          <LogoWhite width="6em" height="6em" />
          <HStack alignItems="top" >
            <Heading as="h1" fontSize="64px" fontWeight="800" ps={5}>HYPE</Heading>
            <Heading as="h1" fontSize="24px" pt={2.5} fontWeight="500">V2</Heading>
          </HStack>
          <Text>by <a href="https://cannonkeys.com/">CannonKeys</a></Text>
        </Stack>

        <Container maxW={maxW} mt={16} h="100%" flex={1}>
          {children}
        </Container>

        {/* Footer */}
        <Box bgColor="gray.600" textColor="gray.300" pt={8} pb={10} mt={20} px={4}>
          <Container maxW="container.xl">
            <Flex direction={["column", "row"]}>

              <Flex direction="column" textAlign={["center", "start"]} align={["center", "start"]} flex={1}>
                <Text fontSize="2xl" fontWeight="600">HYPE V2</Text>
                <Text fontSize="sm">by <a href="https://cannonkeys.com/">CannonKeys</a></Text>
                <Text pt={3} fontSize="sm" as="a" href="https://docs.cannonkeys.com/hype-v2/" _hover={{ textDecoration: "underline" }}>How to use</Text>
                <Box pt={4} />
                <Stack direction="row" fontSize="xl"  spacing={[4, 3, 3]}>
                  <a href="https://twitter.com/cannonkeys" rel="noreferrer" target="_blank"><FaTwitter /></a>
                  <a href="https://www.facebook.com/cannonkeyscom/" rel="noreferrer" target="_blank"><FaFacebookSquare /></a>
                  <a href="https://www.youtube.com/channel/UCkl8NQmxQM5SetgWXs_yZXg" rel="noreferrer" target="_blank"><FaYoutube /></a>
                  <a href="https://instagram.com/cannon.keys" rel="noreferrer" target="_blank"><FaInstagram /></a>
                  <a href="https://discord.com/invite/DKpykqYKAe" rel="noreferrer" target="_blank"><FaDiscord /></a>
                </Stack>
                <Box pt={2} />
              </Flex>

              <Stack direction="column" mt={[8, 0]} textAlign={["center", "end"]} align={["center", "end"]} flex={1} fontSize="sm">
                <Text fontWeight="bold" fontSize="md">Links</Text>
                <Text as="a" href="https://cannonkeys.com/pages/about-us" _hover={{ textDecoration: "underline" }}>About</Text>
                <Text as="a" href="https://cannonkeys.com/pages/contact-us" _hover={{ textDecoration: "underline" }}>Contact</Text>
                <Text as="a" href="https://cannonkeys.com/policies/terms-of-service" _hover={{ textDecoration: "underline" }}>Terms of Service</Text>
                <Text as="a" href="https://cannonkeys.com/pages/privacy-policy" _hover={{ textDecoration: "underline" }}>Privacy Policy</Text>
              </Stack>

            </Flex>
          </Container>
        </Box>
      </Flex>
    )
  }