import React, { useEffect, useState } from "react"
import {
  Box,
  Text,
  Button,
  AspectRatio,
  Image,
  Stack,
  Spinner,
  Skeleton,
  Spacer,
  Tooltip,
  Flex,
} from "@chakra-ui/react"
import { BackendRelease } from "../../api/backend"
import { useGetCollectionByHandleQuery } from "../../generated/graphql"
import { graphQLClient } from "../../api/clients"
import { QuestionOutlineIcon, WarningTwoIcon } from "@chakra-ui/icons"
import { DateTime } from "luxon"
import { ReactComponent as LogoWhite } from '../../assets/LogoWhite-NoText.svg'
import axios from "axios"


export const ReleaseCardBox: React.FC<{
  children: React.ReactNode,
}> = ({
  children
}) => {
    return (
      <Box w="min(100vw, 600px)" rounded="md" overflow="hidden" bgColor="white" textColor="black">
        {children}
      </Box>
    )
  }

export const ReleaseCardTemplate: React.FC<{
  releaseName: string,
  aspectRatioContents: React.ReactNode,
  secondTextLine: React.ReactNode,
  button: React.ReactNode
}> = ({
  releaseName,
  aspectRatioContents,
  secondTextLine,
  button
}) => {
    return (
      <Box w="min(100vw, 600px)" rounded="md" overflow="hidden" bgColor="white" textColor="black">
        <Stack direction={["column", "column"]}>
          <AspectRatio ratio={4 / 3} w={["100%", "100%"]} >
            {aspectRatioContents}
          </AspectRatio>
          <Flex direction={["column", "row"]} pt={[2, 2]} pb={[6, 4]} px={[5, 5, 5]} h="100%" flex={1} align="center">
            <Stack direction="column" textAlign={["center", "start"]}>
              <Text as="h5" fontSize={["xl", "2xl"]} fontWeight="semibold">{releaseName}</Text>
              {secondTextLine}
            </Stack>

            <Spacer />

            <Box mt={[4, 0]}>
              {button}
            </Box>

          </Flex>
        </Stack>
      </Box>
    )
  }


export const ReleaseCard: React.FC<{
  release: BackendRelease,
  onSelectRelease: (collectionHandle: string) => void
}> = ({
  release,
  onSelectRelease
}) => {


    // Query release corresponding to the one specified by backend
    const shopifyRelease = useGetCollectionByHandleQuery(graphQLClient, { input: release.collection_title }, {
      refetchInterval: 60 * 1000,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
      retry: (failureCount, error) => {
        console.log(error)
        if (axios.isAxiosError(error)) {
          console.log(error)
          if (error.response?.status === 429) {
            return true
          }
        }
        return failureCount < 3
      }
    })

    // Get cover image for collection
    const thumbnailSrc = shopifyRelease.data?.collection?.image?.transformedSrc || null

    // Find whether or not the hypemain product has sold out
    const hypemainProduct = shopifyRelease.data?.collection?.products.edges.find(edge => edge.node.tags.includes("hypemain")) || null
    let totalHypemainQty = 0
    if (hypemainProduct) {
      for (const variant of hypemainProduct.node.variants.edges) {
        totalHypemainQty += (variant.node.quantityAvailable || 0)
      }
    }

    // Get the max and min prices of the hypemain product
    const hypemainPrices = hypemainProduct?.node.variants.edges.map(variantEdge => parseFloat(variantEdge.node.priceV2.amount as string)) || []
    const hypemainPriceMax = hypemainPrices.length > 0 ? Math.max.apply(null, hypemainPrices) : null;
    const hypemainPriceMin = hypemainPrices.length > 0 ? Math.min.apply(null, hypemainPrices) : null;

    // Compute whether or not the GB is joinable
    // This one must be computed here since global context manager only calculates for selected active collections
    const releaseTime = DateTime.fromISO(release.release_time)
    const closingTime = DateTime.fromISO(release.closing_time)
    const [lastRefresh, setLastRefresh] = useState<DateTime>(DateTime.now());
    const [gbOpen, setGbOpen] = useState<boolean>(DateTime.now() >= releaseTime && closingTime > DateTime.now())
    useEffect(() => {
      const interval = setInterval(() => {
        const now = DateTime.now()
        setLastRefresh(DateTime.now());
        setGbOpen(now >= releaseTime && closingTime > now)
      }, 1000);
      return () => clearInterval(interval);
    }, [lastRefresh, closingTime, releaseTime]);

    // If error, show an error screen
    if (shopifyRelease.isError) {
      return (
        <ReleaseCardTemplate
          releaseName={release.name}
          aspectRatioContents={
            <Box bgColor="gray.300">
              <WarningTwoIcon boxSize="3em" color="gray.500" />
            </Box>
          }
          secondTextLine={
            <Text>Could not fetch data for this project.<br />Please double-check your internet connection and try again later.<br />If the problem persists, please contact CannonKeys.</Text>
          }
          button={
            <Button colorScheme="ck" disabled>Shop</Button>
          }
        />
      )
    }

    // If loading, show a loading screen
    else if (shopifyRelease.isLoading) {
      return (
        <ReleaseCardTemplate
          releaseName={release.name}
          aspectRatioContents={
            <Box bgColor="gray.300">
              <Spinner boxSize="2em" />
            </Box>
          }
          secondTextLine={
            <Skeleton w="100px" h="1.5em" pt={2} />
          }
          button={
            <Button colorScheme="ck" disabled>Shop</Button>
          }
        />
      )
    }

    // If Shopify-side collection is not found, display an error
    else if (!shopifyRelease.data?.collection) {
      return (
        <ReleaseCardTemplate
          releaseName={release.name}
          aspectRatioContents={
            <Box bgColor="gray.300">
              <QuestionOutlineIcon boxSize="3em" color="gray.500" />
            </Box>
          }
          secondTextLine={
            <Text>Could not locate data for this project on Shopify.<br />Please contact CannonKeys if the problem persists.</Text>
          }
          button={
            <Button colorScheme="ck" disabled>Shop</Button>
          }
        />
      )
    }

    return (
      <ReleaseCardTemplate
        releaseName={release.name}
        aspectRatioContents={
          shopifyRelease.isFetched && thumbnailSrc ?
            <Image src={shopifyRelease.data?.collection?.image?.transformedSrc} alt={release.name} objectFit='cover' />
            :
            <Box bgColor="gray.500">
              <LogoWhite width="min(8em, 25%)" />
            </Box>
        }
        secondTextLine={
          totalHypemainQty <= 0 ?
            // Sold out - don't display price
            <Text>Sold Out</Text>
            :
            // Stock exists - display price
            (hypemainPriceMin &&
              <Text>${hypemainPriceMin !== hypemainPriceMax ? `${hypemainPriceMin!.toFixed(2)} ~ ${hypemainPriceMax!.toFixed(2)}` : hypemainPriceMin!.toFixed(2)}</Text>)
        }
        button={gbOpen ?
          (totalHypemainQty <= 0 ?
            // Sold out
            <Tooltip label={`This project has sold out.`} shouldWrapChildren >
              <Button colorScheme="ck" disabled>Shop</Button>
            </Tooltip>
            :
            // In stock
            <Button colorScheme="ck" onClick={() => onSelectRelease(release.collection_title)}>Shop</Button>
          )
          :
          // Sale period ended
          <Tooltip label={`This project's sale timespan has passed.`} shouldWrapChildren >
            <Button colorScheme="ck" disabled>Closed</Button>
          </Tooltip>
        }
      />
    )
  }